// PrivateRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import NoteListPage from '../pages/NoteListPage';
import Landing from '../pages/Landing';
import AuthContext from '../context/AuthContext';

const PrivateRoute = ({ element: Element, isAuthenticated, ...rest }) => {
  console.log("Private Route")
  let {user} = useContext(AuthContext)
  if (user) {
    return Element;
  }else {
    return <Landing />
  }  
  
};

export default PrivateRoute;
