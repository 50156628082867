

import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  
  const history = useNavigate()
  let SignUpUser = async (e) => {
    e.preventDefault();
    if (e.target.password.value !== e.target.confirm_password.value){
      alert("Your password doesn't match.")
    } else {
      console.log("")
    }
    let response = await fetch("https://api.documind.tech/api/v1/signup/", {
      method: 'POST',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify({'email':e.target.username.value, 
                           'password':e.target.password.value,
                           'confirm_password':e.target.confirm_password.value})
    })
    
    let data = await response.json()
    console.log(data)
    if (response.status === 200){
      localStorage.setItem('verificationToken', JSON.stringify(data));
      history("/verification")
    } if (response.status === 422) {
      alert("Invalid Email Address.")
    }
  };
  return (
    <div class="container px-5">
      <div class="bg-light rounded-4 py-5 px-4 px-md-5">
          <div class="text-center mb-5">
               <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3"><i class="bi bi-file-earmark-person-fill"></i></div>
                    <h1 class="fw-bolder">Sign Up</h1>
                    <p class="lead fw-normal text-muted mb-0">Document your code!</p>
               </div>
           <div class="row gx-5 justify-content-center">
          <div class="col-lg-8 col-xl-6">
            <form onSubmit={SignUpUser}>
            <div class="form-floating mb-3">
              <input class="form-control" type='text' name='username' placeholder='Enter Username'/>
              <label htmlFor="question">Email...</label>
        
              
            </div>
            <div class="form-floating mb-3">
              <input class="form-control" type='password' name='password' placeholder='Enter Password'/>
              <label htmlFor="password">Password...</label>
              
              
            </div>
            <div class="form-floating mb-3">
              <input class="form-control" type='password' name='confirm_password' placeholder='Confirm Password'/>
              <label htmlFor="password">Confirm Password...</label>
              
              
            </div>
            <div class="d-grid">
              <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Signup</button>
            </div>
            </form>
          </div>
          </div>
        </div>
      </div>
     )
    }
    
  export default Signup
   
    
 