import React from 'react'
import video from './videos/rec.mp4'
const Landing = () => {
  
    const scrollToHowItWorks = () => {
        const section = document.getElementById('how-it-works');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };

  return (
    
    <div>
      <header class="py-5">
                <div class="container px-5 pb-5">
                    <div class="row gx-5 align-items-center">
                        <div class="col-xxl-5">
                           
                            <div class="text-center text-xxl-start">
                                <div class="badge bg-gradient-primary-to-secondary text-white mb-4"><div class="text-uppercase">Add &middot; Commit &middot; Push</div></div>
                                <div class="fs-3 fw-light text-muted">DocuMind helps your engineers to</div>
                                <h1 class="display-3 fw-bolder mb-5"><span class="text-gradient d-inline">Document your code | Talk to it too...</span></h1>
                                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                                <button onClick={scrollToHowItWorks} class="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder">Learn More</button>
                                    <a class="btn btn-outline-dark btn-lg px-5 py-3 fs-6 fw-bolder" href="https://dashboard.documind.tech">Login</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-7">
                            
                            <div class="d-flex justify-content-center mt-5 mt-xxl-0">
                                <div class="profile bg-gradient-primary-to-secondary">
                                   
                                    <img class="profile-img" src="assets/profile.png" alt="..." />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section class="bg-light py-5">
                <div class="container px-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-xxl-8">
                            <div class="text-center my-5">
                                <h2 class="display-5 fw-bolder"><span class="text-gradient d-inline">About</span></h2>
                                <p class="lead fw-light mb-4">DocuMind is an AI solution to documenting code in real-time.</p>
                                <p class="text-muted">Our AI software will update your code documentation whenever your engineers writes new code. Documind takes on the burden to instantly make adjustments after any changes have been made, leaving your company with the latest description of how your code works.</p>
                                <div class="d-flex justify-content-center fs-2 gap-4">
                                    <a class="text-gradient" href="https://www.linkedin.com/company/documind-ai/"><i class="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    {/* <section id="services" class="services">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h2>Services</h2>
          <p>Veritatis et dolores facere numquam et praesentium</p>
        </header>

        <div class="row gy-4">

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div class="service-box blue">
              <i class="ri-discuss-line icon"></i>
              <h3>Nesciunt Mete</h3>
              <p>Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div class="service-box orange">
              <i class="ri-discuss-line icon"></i>
              <h3>Eosle Commodi</h3>
              <p>Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div class="service-box green">
              <i class="ri-discuss-line icon"></i>
              <h3>Ledo Markt</h3>
              <p>Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div class="service-box red">
              <i class="ri-discuss-line icon"></i>
              <h3>Asperiores Commodi</h3>
              <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div class="service-box purple">
              <i class="ri-discuss-line icon"></i>
              <h3>Velit Doloremque.</h3>
              <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
            <div class="service-box pink">
              <i class="ri-discuss-line icon"></i>
              <h3>Dolori Architecto</h3>
              <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
              <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
            </div>
          </div>

        </div>

      </div>

    </section> */}
            <section class="services" id="services" style={{ paddingTop:'48px', paddingBottom:'48px'}}>
                <div class="container px-5 mb-5">
                    <div class="text-center mb-5">
                        <h1 class="display-5 fw-bolder mb-0" style={{ marginTop:'48px'}}><span class="text-gradient d-inline">Solutions</span></h1>
                    </div>
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-11 col-xl-9 col-xxl-8">
                           
                            {/* <div class="card overflow-hidden shadow rounded-4 border-0" style={{ marginBottom:'0%'}}>
                                <div class="card-body p-0">
                                    <div class="d-flex align-items-center">
                                        <div class="p-5">
                                            <h2 class="fw-bolder">DocuMind</h2>
                                            <p>DocuMind, our AI bot, analyzes your code and generates documentation. Your documentation is automatically updated with each new commit, ensuring real-time accuracy.</p>
                                        </div> */}
                                        {/* <img class="img-fluid" src="https://dummyimage.com/300x400/343a40/6c757d" alt="..." /> */}
                                        
                                    {/* </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600"> */}
                            <div class="row gy-4">
                              <div class="service-box purple">
                                {/* <i class="ri-discuss-line icon"></i> */}
                                <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3" style={{ marginBottom:"15px"}}><i class="bi bi-file-earmark-code-fill"></i></div>
                                <h3>DocuMind</h3>
                                <p>DocuMind, our AI bot, analyzes your code and generates documentation. Your documentation is automatically updated with each new commit, ensuring real-time accuracy.</p>
                                <a href="#DocuMate" class="read-more"><span>Continue</span> <i class="bi bi-arrow-down"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: '1%' }}>
                          <img class="img-fluid rounded-4 border-0 mb-5" width="50%" src="assets/codeimg.png" alt="..." />
                          <img class="img-fluid rounded-4 border-0 mb-5" width="50%" src="assets/descimg.png" alt="..." />
                        </div>
                        <div class="row gx-5 justify-content-center">
                        <div class="col-lg-11 col-xl-9 col-xxl-8">
                           
                            <div class="row gy-4">
                              <div class="service-box purple" id="DocuMate">
                                {/* <i class="ri-discuss-line icon"></i> */}
                                <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3" style={{ marginBottom:"15px"}}><i class="bi bi-robot"></i></div>
                                <h3>DocuMate</h3>
                                <p>DocuMate provides a personalized platform for inquiring about your code, enabling interactive communication with your codebase. With DocuMate, you can engage in conversations with your code, gaining insights and asking questions seamlessly.</p>
                                
                                <a href="#how-it-works" class="read-more"><span>Continue</span> <i class="bi bi-arrow-down"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <video src={video} width="100%" height="415" controls autoplay/>
                        
                            
                        {/* <div class="card overflow-hidden shadow rounded-4 border-0">
                              <div class="card-body p-0">
                                  <div class="d-flex align-items-center">
                                      <div class="p-5">
                                          <h2 class="fw-bolder">DocuMate</h2>
                                          <p>DocuMate provides a personalized platform for inquiring about your code, enabling interactive communication with your codebase. With DocuMate, you can engage in conversations with your code, gaining insights and asking questions seamlessly.</p>
                                            
                                      </div>
                                        {/* <img class="img-fluid" src="https://dummyimage.com/300x400/343a40/6c757d" alt="..." /> */}    
        
                                        
                                    {/* </div> */}
                                {/* </div> */}
                            {/* </div> */}
                            {/* <iframe src="https://capture.dropbox.com/embed/nLYKqpXJsqoOmhu0?source=copy-embed" width="100%" height="415" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            {/* // <video src={video} width="100%" height="415" controls autoplay/>          */}
      
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </section>
            <section id="how-it-works">
            <div class="container px-5 my-5">
                <div class="text-center mb-5">
                    <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">How it works</span></h1>
                </div>
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-11 col-xl-9 col-xxl-8">
                 
                        
                       
                        <section>
                          
                            <div class="card shadow border-0 rounded-4 mb-5">
                                <div class="card-body p-5">
                                    
                                    <div class="mb-5">
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-github"></i></div>
                                            <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">Integrate your code with DocuMind API</span></h3>
                                            {/* <div class="col-lg-8"><div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus laudantium, voluptatem quis repellendus eaque sit animi illo ipsam amet officiis corporis sed aliquam non voluptate corrupti excepturi maxime porro fuga.</div></div> */}
                                        </div>
                                        <div class="row row-cols-1 row-cols-md-12 mb-4">
                                            <div class="col mb-12 mb-md-0"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">Connect your code seamlessly to the DocuMind API for enhanced functionality. Streamline operations by integrating your code with the powerful capabilities of DocuMind API.</div></div>
                                
                                        </div>
                                       
                                    </div>
                                
                                    <div class="mb-5">
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-robot"></i></div>
                                            <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">DocuMind studies your code</span></h3>
                                        </div>
                                        <div class="row row-cols-1 row-cols-md-12 mb-4">
                                            <div class="col mb-12 mb-md-0"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">DocuMind utilizes machine learning models to analyze and understand your code. It employs advanced algorithms to study and interpret programming structures effectively.</div></div>
                                            
                                        </div>
                                    
                                    </div>
                                    <div class="mb-5">
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-file-earmark-code-fill"></i></div>
                                            <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">Real-time monitoring of changes</span></h3>
                                        </div>
                                        <div class="row row-cols-1 row-cols-md-12 mb-4">
                                            <div class="col mb-12 mb-md-0"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">Constantly track and observe changes as they occur in real time.</div></div>
                                            
                                        </div>
                                    
                                    </div>
                                    <div class="mb-5">
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-robot"></i></div>
                                            <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">Chat with your code</span></h3>
                                        </div>
                                        <div class="row row-cols-1 row-cols-md-12 mb-4">
                                            <div class="col mb-12 mb-md-0"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">Interact with your code through conversation. Engage in chat-based communication with your code.</div></div>
                                            
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            </section>
          
    <section id="pricing" class="pricing">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
        <div class="text-center mb-5">
                    <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Pricing</span></h1>
                </div>
        </header>

        <div class="row gy-4" data-aos="fade-left">

          <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
            <div class="box">
              <h3 style={{color: "#4154f1"}}>Free Plan</h3>
              <div class="price"><sup>$</sup>0<span> / 3 Day Trial</span></div>
              <img src="assets/img/pricing-starter.png" class="img-fluid" alt=""/>
              <ul>
                <li>Access to DocuMate</li>
                <li>Real-time data documentation</li>
                
              </ul>
              <a href="/signup" class="btn-buy">Sign Up</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div class="box">
              
              <h3 style={{color: "#4154f1"}}>Starter Plan</h3>
              {/* <span class="featured">Featured</span> */}
              <div class="price"><sup>$</sup>350<span> / mo</span></div>
              <img src="assets/img/pricing-starter.png" class="img-fluid" alt=""/>
              <ul>
                <li>Access to DocuMate</li>
                <li>Daily Data Documentation <span> / Batch</span></li>
                <li class="na">Real-time data documentation</li>
              </ul>
              <a href="/signup" class="btn-buy">Sign Up</a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div class="box">
              <h3 style={{color: "#4154f1"}}>Business Plan</h3>
              <div class="price"><sup>$</sup>650<span> / mo</span></div>
              <img src="assets/img/pricing-business.png" class="img-fluid" alt=""/>
              <ul>
              <li>Access to DocuMate</li>
                <li>Real-time data documentation</li>
    
              </ul>
              <a href="/signup" class="btn-buy">Sign Up</a>
            </div>
          </div>

    

        </div>

      </div>
                              
    </section>
    </div>
  )
}

export default Landing