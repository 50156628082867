import React, {useContext} from 'react'
import NoteListPage from './NoteListPage'
import DirectoryRepo from './DirectoryRepo'
import AuthContext from '../context/AuthContext'
const Home = () => {
  let {user} = useContext(AuthContext)
  console.log(user)
  return (
    <div>
      <NoteListPage />
      {/* <DirectoryRepo /> */}

    </div>
  )
}

export default Home