import React, {useState, useEffect} from 'react'
import ListItem from '../components/ListItem'
import Header from '../components/Header'
import GitHubConnectButton from '../components/GitHubConnectButton'

const NoteListPage = () => {

  let [notes, setNotes] = useState([])
  const token = localStorage.getItem('authTokens')
  console.log("TOKEN:", JSON.parse(token)['access_token'])
  useEffect(() => {
    getNotes()
  }, [])

  let getNotes = async () => {
    console.log("TOKENss:", JSON.parse(token)['access_token'])
    let response = await fetch('https://api.documind.tech/api/v1/get-file-desc/', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ JSON.parse(token)['access_token'], 
        'Content-Type': 'application/json'
      }
    }
      
    )
    let data = await response.json()
    if (Array.isArray(data)) {
      console.log('DATA:', data)
      setNotes(data)
    } else {
      console.error('Data is not an array:', data)
    }
  } 

  return (
    
    
      
      <div class="p-5">
        {/* <section class="py-5"> */}
          <div class="container px-5 mb-5">
               <div class="text-center mb-5">
                    {/* <GitHubConnectButton /> */}
                    <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Repo Documention</span></h1>
                </div>      
                {notes.map((note, index) => (
                  <ListItem key={index} note={note} />
                  ))
                }
            </div>                  
          {/* </section> */}
        </div>
    
  )
}

export default NoteListPage