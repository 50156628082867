import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const Header = () => {
  let {user, logoutUser} = useContext(AuthContext)
  console.log(user)
  return (
    <div>
      
      <nav class="navbar navbar-expand-lg navbar-light bg-white py-3">
      <div class="container px-5">
        
          <a class="navbar-brand" href="/"><span class="fw-bolder text-primary">DocuMind {user && <a>| Hello, {user.email}</a>}</span></a>
        
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
          
              
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                  
                  
                  {user ? (
                    <>
                    <li class="nav-item"><Link class="nav-link" to="/">Code Directory</Link></li>
                    <li class="nav-item"><Link class="nav-link" to="/documate">DocuMate</Link></li>
                    <li onClick={logoutUser} class="nav-item"><Link class="nav-link">Logout</Link></li>
                    </>
                  ): (<>
                    <li class="nav-item"><Link class="nav-link" to="/">Home</Link></li>
                    <li class="nav-item"><Link class="nav-link" to="/login">Login</Link></li>
                    <li class="nav-item"><Link class="nav-link" to="/contact">Contact</Link></li>
                    </>
                  )}
                 
                  {/* <li class="nav-item"><a class="nav-link" href="projects.html">Projects</a></li> */}
                  {/* <li class="nav-item"><Link class="nav-link" to="/contact">Contact</Link></li> */}
                  
              </ul>
             
          </div>
          
      </div>
  </nav>
  
            
    </div>
    
  )
}

export default Header