import React, {useState, useEffect} from 'react'

const Documate = () => {
  let [notes, setNotes] = useState([])
  let [isLoading, setIsLoading] = useState(false);
  
  const token = localStorage.getItem('authTokens')
  console.log("TOKENn:", JSON.parse(token)['access_token'])
  let submitQuestion = async(e ) => {
    e.preventDefault();
    setIsLoading(true);

    let response = await fetch("https://api.documind.tech/api/v1/question-repo/",{
      method: "POST",
      headers:{
        'Authorization': 'Bearer '+ JSON.parse(token)['access_token'], 
        'Content-Type': 'application/json'
      },
      body:JSON.stringify({'question':e.target.elements.question.value})
    })
    let data = await response.json()
    setIsLoading(false); 
    if(response.status === 200){
     
      setNotes(data)
    }else{
      alert('something went wrong')
      
    }
  }
  return (
    <div class="container px-5">
                   
                    <div class="bg-light rounded-4 py-5 px-4 px-md-5">
                        <div class="text-center mb-5">
                            <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3"><i class="bi bi-robot"></i></div>
                            <h1 class="fw-bolder">Talk to DocuMate</h1>
                            <p class="lead fw-normal text-muted mb-0">Ask anything but the weather...</p>
                        </div>
                        <div class="row gx-5 justify-content-center">
                            <div class="col-lg-8 col-xl-6">
                                
                                <form onSubmit={submitQuestion} id="contactForm" data-sb-form-api-token="API_TOKEN">
                                   
                                    <div class="form-floating mb-3">
                                        <input name='question' class="form-control" id="question" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                                        <label htmlFor="question">Type question here...</label>
                                        <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                                    </div>
                                   
                                    
                                   
                                    <div class="d-none" id="submitSuccessMessage">
                                        <div class="text-center mb-3">
                                            <div class="fw-bolder">Form submission successful!</div>
                                            To activate this form, sign up at
                                            <br />
                                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                        </div>
                                    </div>
                                   
                                    <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                                
                                    <div class="d-grid"><button class="btn btn-primary btn-lg" id="submitButton" type="submit" disabled={isLoading}>
                                    {isLoading ? 'Asking DocuMate...' : 'Ask DocuMate'}</button></div>
                                </form>
                                <div class="p-5">
        
                                <div class="row gx-5 justify-content-center">
          
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                  
                          <div class="p-5">
                                <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">{isLoading ? <i class="bi bi-robot"> working on it...</i> : <i class="bi bi-robot"></i>}</span></h3>
                                <p>{notes.response_q}</p>
                           </div>
                        
               </div>
            </div>
        </div>
        </div>
        </div>
                                
                            </div>
                        </div>
                    
  )
}

export default Documate