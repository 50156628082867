

import React, { useContext } from 'react'
import AuthContext from '../context/AuthContext'

const Login = () => {
  let {loginUser} = useContext(AuthContext)
  return (
    <div class="container px-5">
      <div class="bg-light rounded-4 py-5 px-4 px-md-5">
          <div class="text-center mb-5">
               <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3"><i class="bi bi-lock"></i></div>
                    <h1 class="fw-bolder">Login</h1>
                    <p class="lead fw-normal text-muted mb-0">Read about your code!</p>
               </div>
           <div class="row gx-5 justify-content-center">
          <div class="col-lg-8 col-xl-6">
            <form onSubmit={loginUser}>
            <div class="form-floating mb-3">
              <input class="form-control" type='text' name='username' placeholder='Enter Username'/>
              <label htmlFor="question">Username...</label>
        
              
            </div>
            <div class="form-floating mb-3">
              <input class="form-control" type='password' name='password' placeholder='Enter Password'/>
              <label htmlFor="password">Password...</label>
              
              
            </div>
            <div class="d-grid">
              <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Login</button>
            </div>
            </form>
          </div>
          </div>
        </div>
      </div>
     )
    }
    
  export default Login
   
    
 