// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Login from './pages/login';
import Home from './pages/Home';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext'; 
import Documate from './pages/Documate';
import Contact from './pages/Contact';
import Signup from './pages/Signup';
import VerificationPage from './pages/VerificationPage';

function App() {
  const isAuthenticated = false;
  return (
    <div className="App">
      
      {/* <NoteListPage /> */}
      <Router>
        <AuthProvider>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route
            path="/"
            element={
              <PrivateRoute element={<Home />}
              isAuthenticated={isAuthenticated}
              />
            }
      
        />
        <Route
            path="/documate"
            element={
              <PrivateRoute element={<Documate />}
              isAuthenticated={isAuthenticated}
              />
            }
      
        />
      </Routes>
      </AuthProvider>
    </Router>
      {/* <Landing /> */}
    </div>
  );
}

export default App;
