import React, { useState } from 'react'

const ListItem = ({note}) => {
  const [isDescVisible, setIsDescVisible] = useState(false);

  const toggleDescription = () => {
    setIsDescVisible(!isDescVisible);
  };

  const jdata = JSON.parse(note.desc);
  console.log("FUNCTIONS")
  console.log(jdata['functions'])
  console.log("CLASSES")
  console.log(Array.isArray(jdata['classes']))
  
  


  
  return (
    
      <div class="row gx-5 justify-content-center">
          <div class="col-lg-11 col-xl-9 col-xxl-8">              
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                  <div class="card-body p-0">
                      <div class="d-flex align-items-center">
                          <div class="p-5">
                                <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">File Name: {note.title}</span></h3>
                                <h3 class="fw-bolder">Directory: {note.dir_key}</h3>
                                {jdata['summary'] ? (
                                <>
                                <p class="fw-bolder">Summary</p>
                                <p>{jdata['summary']}</p>
                                </>
                                ):('')}
                                {jdata['imports'] ? (
                                <>
                                <p class="fw-bolder">Imports</p>
                                {jdata['imports'].map((n, i) => (
                                <p key={i}>{n}</p>
                                ))
                                }
                                </>
                                ):('')}
                                {jdata && jdata['classes'] && jdata['classes'].length > 0 ? (
                                <>
                                    <p class="fw-bolder">Classes</p>
                                    {jdata['classes'].map((no, index) => (
                                        <p key={index}><span class="fw-bolder">Class Name: </span>{no['name']}<br/><span class="fw-bolder">Description: </span>{no['description']}<br/>
                                        {no && no['methods'] && no['methods'].length > 0 ? (
                                        <>
                                        {no['methods'].map((mn, mi) => (
                                            
                                            <p key={mi}><span class="fw-bolder">Method: </span>{mn['name']}(): {mn['description']} <br/> </p> 
                                        ))
                                        }
                                        </>
                                        ):("")}</p>
                                    
                                    ))
                                    }
                                </>
                                ):('')}
                                {jdata['functions']  && jdata['functions'].length > 0? (
                                <>
                                <p class="fw-bolder">Functions</p>
                                {jdata['functions'].map((n, i) => (
                                <p key={i}>{n['name']}<br/>{n['description']}</p>
                                ))
                                }
                                </>
                                ):('')}
                                {/* <p style={{ display: isDescVisible ? 'block' : 'none' }}>{note.desc}</p>
                                <button class="btn btn-primary btn-lg" onClick={toggleDescription}>
                                {isDescVisible ? 'Hide Description' : 'Show Description'}
                                </button> */}
                           </div>
                                        {/* <img class="img-fluid" src="https://dummyimage.com/300x400/343a40/6c757d" alt="..." /> */}
                       </div>
                   </div>
               </div>
            </div>
        </div>
  )
}

export default ListItem