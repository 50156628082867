import { createContext, useState, useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext()
const apiUrl = process.env.REACT_APP_DOCUMIND_API;

export default AuthContext;

export const AuthProvider = ({children}) => {
  
  
  let [authTokens, setAuthTokens] = useState(localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
  let [user, setUser] = useState(localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
  
  const history = useNavigate()
  let loginUser = async (e ) => {
    e.preventDefault()
    console.log("Form Submitted")
    console.log("https://api.documind.tech/api/v1/login/")
    let response = await fetch("https://api.documind.tech/api/v1/login/", {
      method: 'POST',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify({'username':e.target.username.value, password:e.target.password.value})
    })
    let data = await response.json()
    if(response.status === 200){
      setAuthTokens(data)
      setUser(jwtDecode(data.access_token))
      localStorage.setItem('authTokens', JSON.stringify(data));
      history("/");
  
    }else{
      alert('something went wrong')
      console.log('RESPONSE:', response.status)
    }
    
  }

  let logoutUser = async () => {
    // let {user} = useContext(AuthContext)
    // const token = localStorage.getItem('authTokens')
    // console.log("TOKEN:", JSON.parse(token)['access_token'])
    // let response = await fetch("http://127.0.0.1:8000/api/v1/logout/",{
    //   method: 'POST',
    //   headers: {
    //     'Authorization': 'Bearer '+ JSON.parse(token)['access_token'], 
    //     'Content-Type': 'application/json'
    //   }
    // })
    // let data = await response.json()
    // if(response.status === 200){
      setAuthTokens(null)
      setUser(null)
      localStorage.removeItem('authTokens')
      history('/')
    // }else{
    //   alert("Something went wrong")
    // }
    
  }

  let contextData = {
    user:user,
    loginUser:loginUser,
    logoutUser:logoutUser
  }
  
  return(
    <AuthContext.Provider value={contextData} >
      {children}
    </AuthContext.Provider>
  )
}