

import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const VerificationPage = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const history = useNavigate()
  let VerifyCodeUser = async (e) => {
    e.preventDefault();
    const verificationToken = JSON.parse(localStorage.getItem('verificationToken'));

    let response = await fetch("https://api.documind.tech/api/v1/verification_code/", {
      method: 'POST',
      headers:{
        'Content-Type':'application/json',
        'Authorization': `Bearer ${verificationToken.verification_token}`
      },
      body:JSON.stringify({'verification_code':e.target.verification_code.value})
    })
    
    let data = await response.json()
    console.log(data)
    if (response.status === 403){
      alert("You inputed the wrong verification code...")
    } if (response.status === 401){
      alert("Your verification code has expired...")
    } if (response.status === 200){
      history("/login")
    }
  };
  return (
    <div class="container px-5">
      <div class="bg-light rounded-4 py-5 px-4 px-md-5">
          <div class="text-center mb-5">
               <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3"><i class="bi bi-file-earmark-person-fill"></i></div>
                    <h1 class="fw-bolder">Finish Registration</h1>
                    <p class="lead fw-normal text-muted mb-0">Input your verification code</p>
               </div>
           <div class="row gx-5 justify-content-center">
          <div class="col-lg-8 col-xl-6">
            <form onSubmit={VerifyCodeUser}>
            <div class="form-floating mb-3">
              <input class="form-control" type='text' name='verification_code' placeholder='Enter Username'/>
              <label htmlFor="question">Verification Code...</label>
        
              
            </div>
        
            <div class="d-grid">
              <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Submit Code</button>
            </div>
            </form>
          </div>
          </div>
        </div>
      </div>
     )
    }
    
  export default VerificationPage
   
    
 