import React, { useState } from 'react'

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    
    const [submitSuccess, setSubmitSuccess] = useState(false);
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('https://api.documind.tech/api/v1/contact-us/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            // Handle success, maybe redirect or show a thank you message
            console.log('Form submitted successfully');
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            });
             setSubmitSuccess(true);
          } else {
            // Handle errors
            console.error('Form submission failed');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

  return (
    <div><section class="py-5">
    <div class="container px-5">

        <div class="bg-light rounded-4 py-5 px-4 px-md-5">
            <div class="text-center mb-5">
                <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3"><i class="bi bi-envelope"></i></div>
                <h1 class="fw-bolder">Get in touch</h1>
                <p class="lead fw-normal text-muted mb-0">Let's work together!</p>
            </div>
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-8 col-xl-6">
            
                    <form onSubmit={handleSubmit} id="contactForm" data-sb-form-api-token="API_TOKEN">
                      
                        <div class="form-floating mb-3">
                            <input class="form-control" id="name" type="text" name="name" placeholder="Enter your name..." value={formData.name} onChange={handleChange} data-sb-validations="required" />
                            <label for="name">Full name</label>
                            <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                        </div>
                        
                        <div class="form-floating mb-3">
                            <input class="form-control" id="email" type="email" name="email" placeholder="name@example.com" value={formData.email} onChange={handleChange} data-sb-validations="required,email" />
                            <label for="email">Email address</label>
                            <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                            <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                        </div>
                    
                        <div class="form-floating mb-3">
                            <input class="form-control" id="phone" type="tel" name="phone" placeholder="(123) 456-7890" value={formData.phone} onChange={handleChange} data-sb-validations="required" />
                            <label for="phone">Phone number</label>
                            <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                        </div>
                      
                        <div class="form-floating mb-3">
                            <textarea class="form-control" id="message" type="text" name="message" placeholder="Enter your message here..." value={formData.message} onChange={handleChange} data-sb-validations="required"></textarea>
                            <label for="message">Message</label>
                            <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                        </div>
                        {submitSuccess ? (
                        <div className="text-center mb-5">
                            <div className="fw-bolder"><i class="bi bi-robot"> Your information has been received...</i></div>
                        </div>
                        ) : (
                        <div className="text-center mb-5">
                        {/* Existing form code here */}
                        {/* ... */}
                        </div>
                        )}
                        <div class="d-none" id="submitSuccessMessage">
                            <div class="text-center mb-3">
                                <div class="fw-bolder">Form submission successful!</div>
                                To activate this form, sign up at
                                <br />
                                <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                            </div>
                        </div>
                    
                       
                        <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                        
                        <div class="d-grid"><button class="btn btn-primary btn-lg" id="submitButton" type="submit">Submit</button></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section></div>
  )
}

export default Contact